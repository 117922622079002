import Amplify from '@aws-amplify/core';
import Storage from '@aws-amplify/storage';
import { withAuthenticator } from 'aws-amplify-react';
import React from 'react';
import ReactDOM from 'react-dom';
import 'semantic-ui-css/semantic.min.css';
import './index.css';
import * as serviceWorker from './serviceWorker';

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
  },
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_GRAPHQL_API_URL,
  aws_appsync_region: process.env.REACT_APP_APPSYNC_REGION,
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_S3_BUCKET,
      region: process.env.REACT_APP_APPSYNC_REGION,
    },
  },
});

Storage.configure({ level: 'private' });

interface State {
  component: React.FunctionComponent | null;
}

class LazyLoadApp extends React.Component<{}, State> {
  public state: State;

  public constructor(props: {}) {
    super(props);

    this.state = {
      component: null,
    };
  }

  public render() {
    const C = this.state.component;

    return C ? <C {...this.props} /> : null;
  }

  public async componentDidMount() {
    const { default: component } = await import('./App');

    this.setState({
      component,
    });
  }
}

const signUpConfig = {
  hideAllDefaults: true,
  signUpFields: [
    {
      label: 'Email',
      key: 'email',
      required: true,
      displayOrder: 1,
      type: 'string',
    },
    {
      label: 'Password',
      key: 'password',
      required: true,
      displayOrder: 2,
      type: 'password',
    },
  ],
};

const AuthApp = withAuthenticator(LazyLoadApp, {
  usernameAttributes: 'email',
  signUpConfig,
});

ReactDOM.render(<AuthApp />, document.getElementById('root') as HTMLElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
